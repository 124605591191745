<template>
  <div class="p-4">
    <h3>
      <b> Encuestas </b>
    </h3>
    <div class="table-responsive px-1 mt-3">
      <table id="datatable_encuestas" class="table table-bordered table-responsive-md table-striped text-center">
        <thead>
          <tr>
            <th>Accesso a formulario</th>
            <th>No. Reporte</th>
            <th>Nombre del formulario</th>
            <th>Descripción</th>
            <th>Nivel PESV</th>
            <th>Fecha inicio</th>
            <th>Fecha final</th>
            <th>Fecha de envío a ST</th>
            <th>Estado actual</th>
          </tr>
        </thead>
        <tbody v-for="(item, index) in listReports" :key="index">
          <tr>
            <td>
              <i v-b-tooltip.hover title="Acceder al formulario" class="fas fa-file-lines pointer zoom"
                style="font-size: 1.5rem" @click="goToForm(item)"></i>
            </td>
            <td>{{ item.numeroReporte }}</td>
            <td>{{ item.encuesta }}</td>
            <td style="max-width: 250px">
              {{ item.descripcion }}
            </td>
            <td>{{ item.clasificacion }}</td>
            <td>{{ item.fechaInicio | formatDate }}</td>
            <td>{{ item.fechaFinal | formatDate }}</td>
            <td>{{ item.fechaEnvioST | formatDate }}</td>
            <td>
              <b-badge :variant="`${item.estado == 'Finalizado' ? 'success' : 'danger'
                }`">{{ item.estado }}</b-badge>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import "moment/locale/es";
moment.locale("es"); // use Spanish globally
const Swal = require("sweetalert2");

import { core } from "@/config/pluginInit";
import { mapActions, mapState } from "vuex";
export default {
  props: {
    fase: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      userLogged: null,
      dataTableInstance: null,
      listReports: [],
    };
  },
  mounted() {
    this.initializeData();
  },
  watch: {
    fase() {
      this.getListReports();
    },
  },
  computed: {
    ...mapState("pesv", ["TOKEN_SISI", "credential"]),
  },
  methods: {
    ...mapActions("pesv", ["useRequestSisi"]),
    async initializeData() {

      // obtener usuario activo
      this.userLogged = JSON.parse(localStorage.getItem("setPersonaAct"));

      // Verificar si existe autenticación de Sisi
      const isSisiAuth = localStorage.getItem("sisiAuth");

      if (!isSisiAuth) {
        Swal.fire(
          "Error!",
          "No se encontraron datos de autenticación de Sisi",
          "error"
        )
        return
      }

      await this.getListReports();
      this.initializedTable();
    },
    goToForm(item) {

      const query = {
        vigilado: item.idVigilado,
        reporte: item.numeroReporte,
      }

      if (item.vigencia) {
        query.vigencia = item.vigencia
      }

      this.$router.push({
        path: `/helex/pesv/encuestas/${item.idEncuestaDiligenciada}`,
        query
      });
    },
    async getListReports() {

      this.$isLoading(true);
      // obtener lista de reportes de Sisi
      const encuestasResponse = await this.useRequestSisi({
        method: "GET",
        path: "encuestas/listar-reportes",
        params: {
          pagina: 1,
          limite: 10,
          idVigilado: this.credential.usuario,
          idEncuesta: this.fase === 0 ? 1 : 2,
          token: this.TOKEN_SISI,
        },
      });

      if (!encuestasResponse.ok) {
        this.$isLoading(false);
        return
      }

      // guardar lista de reportes en helexium
      await this.$store.dispatch("hl_post", {
        path: "PesvPasosEvaluacion/CreateListReports/",
        data: {
          empresaId: this.userLogged.empresa.id,
          fase: this.fase === 0 ? 1 : 2,
          respuesta: JSON.stringify(encuestasResponse.data)
        }
      });

      // obtener lista de reportes guardada en helexium
      const dataReports = await this.$store.dispatch("hl_get", {
        path: `PesvPasosEvaluacion/GetListReportByEmpresaAndFase/${this.userLogged.empresa.id}/${this.fase === 0 ? 1 : 2}`,
      });

      const { success, message, data } = dataReports;

      if (!success) {
        this.$isLoading(false);
        Swal.fire(
          "Error!",
          message,
          "error"
        );
        return
      }

      if (data) {
        this.listReports = JSON.parse(data.respuesta).reportadas;
      }

      this.$isLoading(false);
    },
    initializedTable() {
      core.index();
      if (this.dataTableInstance) {
        this.dataTableInstance.destroy();
      }
      this.dataTableInstance = window.$("#datatable_encuestas").DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        },
      });
    },
  },
  filters: {
    formatDate(date) {
      return date
        ? moment(date).locale("es").format("D MMM YYYY")
        : "No hay fecha";
    },
  },
};
</script>

<style scoped></style>
