var render = function render(){var _vm=this,_c=_vm._self._c;return _c('card',[_c('div',{staticClass:"custom-container"},[_c('h3',{staticClass:"p-3"},[_c('b',[_vm._v("Sistema de Gestión : PESV")])]),_c('div',{staticClass:"row mx-3",staticStyle:{"gap":"0.5rem"}},_vm._l((_vm.fases),function(fase,index){return _c('div',{key:fase.id,staticClass:"col-12 col-md-2 bg-dark bg-dark-hover p-3 rounded d-flex justify-content-between",style:({
          transition: 'box-shadow 0.3s ease',
          boxShadow: _vm.activeFase == index ? 'inset 0 0 0 2px #186bdd' : 'none',
          cursor: 'default',
        }),on:{"click":function($event){return _vm.handlerActiveFase(index)}}},[_vm._v(" "+_vm._s(fase.text)+" "),_c('i',{staticClass:"fas fa-circle-check icon",class:{
          show: _vm.activeFase == index,
        }})])}),0),_c('hr'),_c('ListEncuesta',{attrs:{"fase":_vm.activeFase}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }